(() => {
	const siteName = 'default-package';
	const htmlHasClass = (_class) => { return document.documentElement.classList.contains(_class); };
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: window.innerWidth,
				height: window.innerHeight
			},
			header: {
				desktop: 120,
				sp: 62
			}
		},
		localDecision() {
			const regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() { // ローカル環境ならincludeをAjaxで読み込む
			const _this = this;
			const key = 'inc_';
			let ajaxArray = [];
			let ajaxArrayList = [];
			let includeClass = document.querySelectorAll(`[class*="${key}"]`);

			includeClass.forEach(ele => {
				let path = ele.innerHTML.split(' ')[1];
				ajaxArray.push(path);
			});

			if (ajaxArray.length) {
				ajaxArray.forEach(ele => {

					let xhr = new XMLHttpRequest();
					let promise = new Promise((resolve, reject) => {
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4) {
								if (xhr.status === 200) {
									resolve(xhr.responseText);
								} else {
									reject(xhr.statusText);
								}
							}
						};
					});
					xhr.open('GET', ele + '.html', true);
					xhr.send();
					ajaxArrayList.push(promise);
				});

				Promise.all(ajaxArrayList).then(results => {
					let regExp = new RegExp(key);

					results.forEach(ele => {
						let classList = ele.split(/ |\"/g);
						let position;

						classList.forEach(ele2 => {
							if (ele2.match(regExp)) { position = ele2; }
						});
						document.querySelectorAll('.' + position).forEach(ele2 => {
							ele2.outerHTML = ele;
						});

					});

					_this.loadDelayScript();
				}).catch((error) => {
					console.error('Failed to read the include file:', error);
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		getQuery() {
			let vars = [];
			let hash = null;
			let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			hashes.forEach(ele => {
				hash = ele.split('=');
				hash[1] = (hash[1].indexOf('#') !== -1) ? hash[1].split('#')[0] : hash[1];
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			});
			return vars;
		},
		accordion() {
			const details = document.querySelectorAll('.js-accordion');

			details.forEach(ele => {
				ele.dataset.isOpen = 'false';
				const summary = ele.querySelector('summary');
				const wrapper = ele.querySelector('.mod-accordion-wrapper');
				const plus = ele.querySelector('.mod-accordion-plus');
				summary.addEventListener('click', e => {
					e.preventDefault();
					if (ele.open) {
						const close = wrapper.animate(
							{
								opacity: [1, 0],
								height: [wrapper.offsetHeight + 'px', 0],
							},
							{
								duration: 300,
								easing: 'ease',
							}
						);
						plus.animate(
							{ rotate: ['90deg', '0deg'] },
							{
								duration: 300,
								pseudoElement: "::after",
								easing: 'ease',
								fill: 'forwards',
							}
						);
						close.onfinish = () => {
							ele.dataset.isOpen = 'false';
							ele.removeAttribute('open');
						}
					} else {
						ele.dataset.isOpen = 'true';
						ele.setAttribute('open', 'true');
						wrapper.animate(
							{
								opacity: [0, 1],
								height: [0, wrapper.offsetHeight + 'px'],
							},
							{
								duration: 300,
								easing: 'ease',
							}
						);
						plus.animate(
							{ rotate: ['0deg', '90deg'] },
							{
								duration: 300,
								pseudoElement: "::after",
								easing: 'ease',
								fill: 'forwards',
							}
						);
					}
				});
			});
		},
		tabSwitch() {
			let $tab = document.querySelectorAll('.js-tab');
			let $content = document.querySelectorAll('.js-tab-content');
			if ($tab.length) {
				// ハッシュタグで絞り込み
				if (location.hash) {
					let hash = location.hash.split('#')[1];
					$tab.forEach(ele => {
						ele.classList.remove(a);
						if (ele.getAttribute('data-target').match(hash)) {
							ele.classList.remove(a);
						}
					});
					$content.forEach(ele => {
						ele.classList.remove(a);
						ele.classList.remove(v);
						if (ele.getAttribute('data-target').match(hash)) {
							$(ele).addClass(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						}
					});
				}

				$tab.forEach(ele => {
					ele.addEventListener('click', () => {
						$tab.forEach(ele2 => { ele2.classList.remove(a); })
						ele.classList.add(a);
						$content.forEach(ele => {
							ele.classList.remove(a);
							ele.classList.remove(v);
						});

						let $target;
						let target = ele.getAttribute('data-target');
						if (target === 'all') {
							$target = $content;
						} else {
							$target = document.querySelectorAll('[data-target="' + target + '"]');
						}
						$target.forEach(ele => {
							ele.classList.add(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						});
					});
				});
			}
		},
		easeScroll() {
			const scrollToObject = (_self) => {
				let hash = _self.currentTarget.href.split('#')[1];
				let pos = hash === 'pagetop' ? 0 : document.getElementById(hash).getBoundingClientRect().top;
				let offset = hash === 'pagetop' ? 0 : window.scrollY;
				pos += offset;

				if (hash !== 'pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos = pos - FUNCTIONS.va.header.sp;
					} else {
						pos = pos - FUNCTIONS.va.header.desktop;
					}
				}
				window.scrollTo({ top: pos, behavior: 'smooth' });
			}
			// フォーカス用関数
			const scrollFocus = (_hash) => {
				let hash = _hash;
				if (hash !== 'pagetop') {
					const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
					const targetElement = document.getElementById(hash);

					if (targetElement) { // targetElement が存在するか確認
						const focusableElements = Array.from(targetElement.querySelectorAll(focusableElementsSelector));
						if (focusableElements.length) {
							focusableElements[0].focus({ preventScroll: true });
						}
					}
				}
			};
			// スクロール完了後に実行する関数
			let scrollResolveTimeout;
			const waitForScrollComplete = (_hash) => {
				return new Promise((resolve) => {
					const scrollTimeout = setTimeout(() => {
						resolve(true);
					}, 100);
					const scrollTimeoutEvent = addEventListener('scroll', function fn(e) {
						clearTimeout(scrollTimeout);
						clearTimeout(scrollResolveTimeout);
						scrollResolveTimeout = setTimeout(function () {
							scrollFocus(_hash);
							removeEventListener('scroll', fn);
							resolve(true);
						}, 100);
					});
				});
			};
			document.querySelectorAll('a[rel="scroll"]').forEach((ele) => {
				ele.addEventListener('click', async (e) => {
					e.preventDefault();
					if (/#/.test(e.currentTarget.href)) {
						scrollToObject(e);
						let hash = e.currentTarget.href.split('#')[1];
						await waitForScrollComplete(hash);
						return false;
					}
				});
			});
		},
		setImgAttrWidthHeight() {
			const getImg = (_src) => {
				return new Promise((resolve, reject) => {
					const image = new Image();
					image.src = _src;
					image.onload = () => { resolve(image); }
					image.onerror = (error) => { reject(error); }
				});
			};

			const imgs = document.getElementsByTagName('img');

			for (const img of imgs) {
				const src = img.getAttribute('src');
				getImg(src).then((res) => {
					if (!img.hasAttribute('width')) {
						img.setAttribute('width', res.width);
					}
					if (!img.hasAttribute('height')) {
						img.setAttribute('height', res.height);
					}
				})
					.catch((error) => { console.log(error); });
			}
		},
		easeScrollHash() {
			const hash = location.hash;
			if (hash) {
				// フォーカス用関数
				const scrollFocus = (_hash) => {
					let hash = _hash;
					if (hash !== 'pagetop') {
						const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
						const targetElement = document.getElementById(hash);

						if (targetElement) { // targetElement が存在するか確認
							const focusableElements = Array.from(targetElement.querySelectorAll(focusableElementsSelector));
							if (focusableElements.length) {
								focusableElements[0].focus({ preventScroll: true });
							}
						}
					}
				};
				// スクロール完了後に実行する関数
				let scrollResolveTimeout;
				const waitForScrollComplete = (_hash) => {
					return new Promise((resolve) => {
						const scrollTimeout = setTimeout(() => {
							resolve(true);
						}, 100);
						const scrollTimeoutEvent = addEventListener('scroll', function fn(e) {
							clearTimeout(scrollTimeout);
							clearTimeout(scrollResolveTimeout);
							scrollResolveTimeout = setTimeout(function () {
								scrollFocus(_hash);
								removeEventListener('scroll', fn);
								resolve(true);
							}, 100);
						});
					});
				}


				const url = window.location.href;
				if (!url.includes("smilestand") && !url.includes("3min") && !url.includes("jihankiconsul/recommend")) {
					FUNCTIONS.setImgAttrWidthHeight();
				}

				window.addEventListener('load', async () => {
					document.documentElement.style.display = 'none';
					document.body.style.display = 'none';

					setTimeout(() => {

						document.documentElement.style.display = 'block';
						document.body.style.display = 'block';

						let pos = document.getElementById(hash.split('#')[1]).getBoundingClientRect().top;

						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos -= FUNCTIONS.va.header.sp;
						} else {
							pos -= FUNCTIONS.va.header.desktop;
						}
						window.scrollTo({ top: pos, behavior: 'smooth' });
						if (document.querySelector('.js-top-slide01')) {
							const arrowpos = () => {
								document.querySelectorAll('.js-top-slide01 .splide__arrow').forEach(ele => {
									if (document.querySelector('.is-active.is-visible .top-slide01-item-img')) {
										let postop = (document.querySelector('.is-active.is-visible .top-slide01-item-img').clientHeight - ele.clientHeight) / 2;
										ele.style.top = postop + 'px';
									}
									ele.style.opacity = 1;
								});
							};
							const pagenationpos = () => {
								document.querySelectorAll('.js-top-slide01 .splide__track').forEach(ele => {
									let eleHeight = ele.clientHeight;
									ele.style.minHeight = eleHeight + 'px';
								});
							};
							arrowpos();
							pagenationpos();
						}
						setTimeout(FUNCTIONS.matchHeight(),1000);

					}, 500);
					await waitForScrollComplete(hash);
				});
			}
		},
		pageTop() {
			let footer = document.querySelector('.footer');
			let $pagetop = document.querySelector('.footer-pagetop');

			if ($pagetop) {
				window.addEventListener('scroll', (e) => {
					if (window.scrollY > '100') {
						$pagetop.classList.add(v);
					} else {
						$pagetop.classList.remove(v);
					}
				});
			}
		},
		setModal() {
			const createModal = (_appendHTML, _target) => {
				const $container = document.createElement('div');
				$container.className = 'mod-modal-overlay';
				$container.innerHTML = _appendHTML;
				document.body.appendChild($container);

				$container.classList.add(a);
				setTimeout(() => {
					$container.classList.add(v);
					document.querySelectorAll('.mod-modal-closeArea, .mod-modal-closeBtn, .mod-modalContent a').forEach((ele) => {
						ele.addEventListener('click', (e) => {
							$container.classList.remove(v);
							setTimeout(() => {
								$container.classList.remove(a);
								$container.remove();
								_target.focus();
							}, 400);
						});
					});
					window.addEventListener("keydown", function (event) {
						if (document.querySelectorAll('.mod-modal-overlay').length) {
							// タブキーが押された時
							if (event.key === "Tab") {
								event.preventDefault();
								// モーダル要素内のフォーカス可能な要素の一覧を取得
								const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
								const focusableElements = Array.from(
									document.querySelector('.mod-modal-overlay').querySelectorAll(focusableElementsSelector)
								);
								// 現在のフォーカス位置を取得
								const focusedItemIndex = focusableElements.indexOf(document.activeElement);
								// shiftキーと同時に押されてた場合
								if (event.shiftKey) {
									if (focusedItemIndex === 0) {
										// 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
										focusableElements[focusableElements.length - 1].focus();
									} else {
										// 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
										focusableElements[focusedItemIndex - 1].focus();
									}
								} else {
									if (focusedItemIndex === focusableElements.length - 1) {
										// 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
										focusableElements[0].focus();
									} else {
										// 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
										focusableElements[focusedItemIndex + 1].focus();
									}
								}
							}
							// ESCキーが押された時
							if (event.key === "Escape") {
								event.preventDefault();
								$container.classList.remove(v);
								setTimeout(() => {
									$container.classList.remove(a);
									$container.remove();
									_target.focus();
								}, 400);
							}
						}
					});
				});
			};
			document.querySelectorAll('.mod-modal').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let targetKey = e.currentTarget.getAttribute('data-target');
					let $content = document.querySelector(`.mod-modalContent[data-target="${targetKey}"]`);
					if ($content) {
						let closeButton = document.createElement('button');
						closeButton.setAttribute('type', 'button');
						closeButton.className = 'mod-modal-closeBtn';
						closeButton.setAttribute('aria-label', 'close');
						closeButton.innerHTML = '<span aria-hidden="true"></span>';

						$content.appendChild(closeButton);

						let closeArea = document.createElement('div');
						closeArea.className = 'mod-modal-closeArea';
						let containerDiv = document.createElement('div');
						containerDiv.appendChild(closeArea);
						containerDiv.appendChild($content.cloneNode(true));

						createModal(containerDiv.innerHTML, e.currentTarget);
					}
					return false;
				});
			});

			// 画像1個だけのモーダル
			document.querySelectorAll('.mod-modalImg').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let appendHTML = `<div class="mod-modal-closeArea"></div>
						<button type="button" class="mod-modal-closeBtn" aria-label="close"><span aria-hidden="true"></span></button>
						${e.currentTarget.querySelector('img').outerHTML}`;
					createModal(appendHTML, e.currentTarget);
					return false;
				});
			});
		},
		matchHeight() {
			const $elements = document.querySelectorAll('[data-mh]');
			const groups = new Map();

			$elements.forEach(ele => { ele.style.height = 'auto'; }); // 初期化

			$elements.forEach(ele => {
				const attributeName = ele.getAttribute('data-mh');
				if (!groups.has(attributeName)) {
					groups.set(attributeName, []);
				}
				groups.get(attributeName).push(ele);
			});
			groups.forEach(group => {
				const attributeGroups = new Map();
				group.forEach(ele => {
					const attributeName = ele.getAttribute('data-mh');
					const topPosition = ele.getBoundingClientRect().top;
					const key = attributeName + '_' + topPosition;
					if (!attributeGroups.has(key)) {
						attributeGroups.set(key, []);
					}
					attributeGroups.get(key).push(ele);
				});
				attributeGroups.forEach((item) => {
					const maxHeight = Math.max(...item.map(ele => ele.offsetHeight));
					if(maxHeight===0) return;
					item.forEach((ele2) => {
						ele2.style.height = `${maxHeight}px`;
					});
				});
			});
		},
		inViewAnimation() {
			const target = document.querySelectorAll('.js-fade');
			const options = {
				root: null,
				rootMargin: '-30% 0px',
				threshold: 0
			};
			const callback = (entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						entry.target.classList.add(v);
					}
				});
			};
			const observer = new IntersectionObserver(callback, options);

			target.forEach(ele => {
				observer.observe(ele);
			});
		},
		headerMegamenu() {
			// メガメニュー表示／非表示
			let megaMenu = document.querySelectorAll('.header-nav-main li .__toggleBtn');
			megaMenu.forEach(ele => {
				ele.addEventListener('click', (e) => {
					document.querySelectorAll('.header-nav-main li .__toggleBtn').forEach(ele2 => {
						if (ele2 !== e.currentTarget) {
							ele2.classList.remove(a);
						}
					});
					document.querySelectorAll('.js-megaMenu').forEach(ele2 => {
						if (ele2.getAttribute('data-name') !== e.currentTarget.getAttribute('data-name')) {
							ele2.classList.remove(a);
						}
					});
					e.currentTarget.classList.toggle(a);
					if (e.currentTarget.getAttribute('data-name')) {
						let thisName = e.currentTarget.getAttribute('data-name');
						console.log(thisName)

						let thisMegaMenu = document.querySelector('.js-megaMenu[data-name="' + thisName + '"]');
						thisMegaMenu.classList.toggle(a);
					}
				});
				// ele.addEventListener("keydown", (e) => {
				// 	// Enterキーでメガメニューの開閉
				// 	if( e.keyCode === 13 ){
				// 		let activeBtn = document.querySelectorAll('.js-megaMenu.__pc.is-active');
				// 		let activeBlock = document.querySelectorAll('.js-megaMenu-block.is-active');
				// 		if(activeBtn.length) {
				// 			activeBtn[0].classList.remove(a);
				// 			activeBlock[0].classList.remove(a);
				// 		} else {
				// 			e.currentTarget.classList.add(a);
				// 			let thisName = e.currentTarget.getAttribute('data-name');
				// 			let thisMegaMenu = document.querySelector('.js-megaMenu-block[data-name="'+ thisName +'"]');
				// 			thisMegaMenu.classList.add(a);
				// 			// thisMegaMenu.style.height = thisMegaMenu.scrollHeight + 'px';
				// 		}
				// 	}
				// });
			});
			// クローズボタンでメガメニュー非表示
			let closeBtn = document.querySelectorAll('.js-megaMenu-close');
			closeBtn.forEach(ele => {
				ele.addEventListener('click', e => {
					menuClose();
				});
			});
			// メガメニュー内aタグ・buttonタグ選択時escキーで閉じる
			let megahead = document.querySelectorAll('.header-nav-main a,.header-nav-main button');
			megahead.forEach(ele => {
				ele.addEventListener("keydown", (e) => {
					// ESCキーでメガメニューを閉じる
					if (e.keyCode === 27) {
						menuClose();
					}
				});
			});
			// <PC>オーバーレイをホバーしたらメニュー非表示
			let megamenuoverlay = document.querySelectorAll('.header-megamenu-overlay');
			megamenuoverlay.forEach(ele => {
				ele.addEventListener('mouseover', (e) => {
					menuClose();
				});
			});
			// <SP>メガメニュー開閉
			let spMegaMenu = document.querySelectorAll('button.js-megaMenu.__sp');
			spMegaMenu.forEach(ele => {
				ele.addEventListener('click', (e) => {
					let thisName = ele.getAttribute('data-name');
					let thisMegaMenu = document.querySelector('.js-megaMenu-block[data-name="' + thisName + '"]');
					ele.classList.toggle(a);
					thisMegaMenu.classList.toggle(a);
				});
			});
			// 全て閉じる
			const menuClose = () => {
				console.log("menuClose")
				let activeBtn = document.querySelectorAll('.__toggleBtn.is-active');
				let activeBlobck = document.querySelectorAll('.js-megaMenu.is-active');
				if (activeBtn.length) {
					activeBtn[0].classList.remove(a);
				}
				if (activeBlobck.length) {
					activeBlobck[0].classList.remove(a);
				}
			}
		},
		headerHamburger() {
			const $hamburger = document.querySelector('.header-hamburger');
			const $nav = document.querySelector('.header-nav');
			const $container = document.querySelector('.container');
			let scrollTopNow;

			const fixedOnContainer = () => {
				scrollTopNow = window.scrollY;
				$container.style.position = 'fixed';
				$container.style.width = '100%';
				$container.style.top = -1 * scrollTopNow;
				$container.style.left = 0;
				document.body.classList.add(f);
			};
			const fixedOffContainer = () => {
				$container.style.position = 'static';
				document.body.classList.remove(f);
				window.scrollTo({ top: scrollTopNow });
			};

			if ($hamburger) {
				$hamburger.addEventListener('click', (e) => {
					e.currentTarget.classList.toggle(a);
					if ($nav.classList.contains(a)) {
						$nav.classList.remove(v);
						$nav.classList.remove(a);
						fixedOffContainer();
					} else {
						fixedOnContainer();
						$nav.classList.add(a);
						setTimeout(() => { $nav.classList.add(v); });
					}
				});
			}
		},
		headerHamburgerConsul() {
			const $hamburger = document.querySelector('.header-consul-hamburger');
			const $nav = document.querySelector('.header-consul-nav');
			const $container = document.querySelector('.container');
			let scrollTopNow;

			const fixedOnContainer = () => {
				scrollTopNow = window.scrollY;
				$container.style.position = 'fixed';
				$container.style.width = '100%';
				$container.style.top = -1 * scrollTopNow;
				$container.style.left = 0;
				document.body.classList.add(f);
			};
			const fixedOffContainer = () => {
				$container.style.position = 'static';
				document.body.classList.remove(f);
				window.scrollTo({ top: scrollTopNow });
			};

			if ($hamburger) {
				$hamburger.addEventListener('click', (e) => {
					e.currentTarget.classList.toggle(a);
					if ($nav.classList.contains(a)) {
						$nav.classList.remove(v);
						$nav.classList.remove(a);
						fixedOffContainer();
					} else {
						fixedOnContainer();
						$nav.classList.add(a);
						setTimeout(() => { $nav.classList.add(v); });
					}
				});
			}
		},
		fileSize() {
			const bytesToSize = (bytes) => {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) {
					return 'n/a';
				}
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
				if (i === 0) {
					return bytes + ' ' + sizes[i];
				}
				return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
			}
			if (document.querySelectorAll('.js-fileSize').length) {
				document.querySelectorAll('.js-fileSize').forEach(function (pdfElement) {
					pdfElement.innerText = bytesToSize(pdfElement.innerText);
				});
			}
		},
		removeElmIfEmpty() {
			// ＜ルート設定対応＞要素が空だったら削除
			const $eleList = document.querySelectorAll('.js-checkEmptyElm');
			$eleList.forEach(ele => {
				if (ele.textContent.trim() == 0) {
					ele.classList.add('di_none');
				}
			});
		},
		removeBlockIfListEmpty() {
			// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
			const $eleList = document.querySelectorAll('.js-checkEmptyList');
			if ($eleList) {
				$eleList.forEach(ele => {
					if (!ele.getElementsByTagName('li').length) {
						ele.closest('.js-checkEmptyList-wrap').classList.add('di_none');
					}
				});
			}
		},
		share() {
			const sharelist = document.querySelectorAll('.mod-share')
			if (sharelist.length) {
				var pageUrl = document.URL;
				var shareUrl = "";
				var sharePagetitle = encodeURIComponent(document.title);
				sharelist.forEach(elem => {
					if (elem.querySelector('._shareFacebook')) {
						// facebook
						shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + pageUrl;
						elem.querySelector('._shareFacebook').setAttribute('href', shareUrl);
					}
					if (elem.querySelector('._shareX')) {
						// X
						shareUrl = 'https://twitter.com/share?url=' + pageUrl + '&text=' + sharePagetitle;
						elem.querySelector('._shareX').setAttribute('href', shareUrl);
					}
					if (elem.querySelector('._shareLine')) {
						// LINE
						shareUrl = 'https://social-plugins.line.me/lineit/share?url=' + pageUrl;
						elem.querySelector('._shareLine').setAttribute('href', shareUrl);
					}
				});
			}
		},
		copyURL() {
			const copyBtns = document.querySelectorAll('.mod-copyBtn');
			if (copyBtns.length) {
				copyBtns.forEach(copyBtn => {
					copyBtn.addEventListener('click', function () {
						const element = document.createElement('input');
						element.value = location.href;
						document.body.appendChild(element);
						element.select();
						document.execCommand('copy');
						document.body.removeChild(element);

						const copyBtnTxt = copyBtn.querySelector('.mod-copyBtn-text');
						console.log(copyBtn, copyBtnTxt)
						if (copyBtnTxt) {
							copyBtnTxt.textContent = 'コピーしました';
							setTimeout(function () {
								copyBtnTxt.textContent = 'このページのURLをコピーする';
							}, 1000);
						}
					});
				});
			}
		},
		URLcurrent() {
			if (document.querySelector('.js-current')) {
				document.querySelectorAll('.js-current').forEach(item1 => {
					// 各要素の href を取得し、現在のページのURLに基づいた絶対パスに変換
					const linkPath = new URL(item1.getAttribute('href'), location.origin).pathname.replace(/index\.html$/, '');
					const currentPath = location.pathname.replace(/index\.html$/, '');

					// 現在のページのパスがリンクのパスで始まる場合、is-activeを追加
					if (currentPath.startsWith(linkPath)) {
						item1.classList.add('color_red');
					}
				});
			}
		},
		setCheckboxLabel() {
			if (document.querySelector(".mod-checkbox")) {
				document.querySelectorAll('.mod-checkbox').forEach(item => {
					const input = item.querySelector('input');
					if (input && input.id) {
						item.setAttribute('for', input.id);
					}
				});
			}
			if (document.querySelector(".mod-radio")) {
				document.querySelectorAll('.mod-radio').forEach(item => {
					const input = item.querySelector('input');
					if (input && input.id) {
						item.setAttribute('for', input.id);
					}
				});
			}
		},
		loadDelayScript() {
			let _this = this;
			_this.accordion();
			_this.tabSwitch();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.setModal();
			_this.matchHeight();
			_this.inViewAnimation();
			_this.headerHamburger();
			_this.headerHamburgerConsul();
			_this.fileSize();
			_this.removeElmIfEmpty();
			_this.removeBlockIfListEmpty();
			_this.headerMegamenu();
			_this.share();
			_this.copyURL();
			_this.URLcurrent();
			_this.setCheckboxLabel();

			let resizeTime = 0;
			window.addEventListener('resize', () => {
				clearTimeout(resizeTime);
				resizeTime = setTimeout(() => { _this.matchHeight(); }, 300);
			});
			window.addEventListener('load', function () {
				setTimeout(() => { _this.matchHeight(); }, 100);
			});
		}
	};

	document.addEventListener('DOMContentLoaded', () => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})();
